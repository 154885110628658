import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import type { Swiper as SwiperClass } from 'swiper';
import Conditional from 'components/common/Conditional';
import OverflowScroll from 'UI/OverflowScroll';
import { HALYARD, SIZES } from 'const/ui-constants';
import ChevronLeftCircle from 'assets/chevronLeftCircle';

const Swiper = dynamic(() => import('components/Swiper'));

const StyledCarousel = styled.div<{ $spaceBetween: number }>`
  position: relative;

  .swiper-initialized {
    width: 100%;
  }
  .swiper:not(.swiper-initialized) .swiper-wrapper {
    gap: ${({ $spaceBetween }) => $spaceBetween}px;
  }
`;

const StyledSwiper = styled.div`
  overflow: hidden;
  display: flex;
  position: relative;
  max-width: ${SIZES.MAX_WIDTH};
  .swiper-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    margin-bottom: 24px;
  }
`;

const Controls = styled.div`
  .prev-slide,
  .next-slide {
    position: absolute;
    top: 100px;
    transform: translateY(-50%);
    left: -20px;
    cursor: pointer;
    z-index: 2;
    svg {
      fill: #fff;
      circle {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
      border-radius: 100%;
      box-shadow: path {
        stroke-width: 2px;
      }
    }
  }
  .next-slide {
    left: unset;
    right: -20px;
    svg {
      transform: rotateY(180deg);
    }
  }
`;

const EntrySection = styled.div`
  margin-bottom: 32px;
  font-family: ${HALYARD.FONT_STACK};
  @media (max-width: 500px) {
    margin-bottom: 24px;
  }
`;

interface CarouselProps {
  cardsInARow?: number;
  spaceBetween?: number;
  entrySection?: JSX.Element;
  children?: any[];
  mobileMinWidth?: number | string;
  slidesPerGroup?: number;
  goNextHandler?: Function;
  goPrevHandler?: Function;
  isMobile?: boolean;
  breakpoints?: Record<number, any>;
}

const Carousel: FunctionComponent<React.PropsWithChildren<CarouselProps>> = ({
  cardsInARow = 1,
  spaceBetween = 24,
  entrySection,
  children,
  mobileMinWidth = 'calc(100vw - 93px)',
  slidesPerGroup = 1,
  goNextHandler = () => {},
  goPrevHandler = () => {},
  isMobile,
  breakpoints = {},
}) => {
  const [swiper, updateSwiper] = useState<SwiperClass | null>(null);
  const [_currentIndex, updateCurrentIndex] = useState<number | undefined>(0);
  const updateIndex = useCallback(
    () => updateCurrentIndex(swiper?.realIndex),
    [swiper]
  );

  useEffect(() => {
    if (isMobile) return;
    if (swiper !== null) {
      (swiper as any).on('slideChange', updateIndex);
    }
    return () => {
      if (swiper !== null) {
        (swiper as any).off('slideChange', updateIndex);
      }
    };
  }, [isMobile, swiper, updateIndex]);

  if (!isMobile) {
    const goNext = () => {
      if (swiper !== null) {
        goNextHandler();
        (swiper as any).slideNext();
      }
    };

    const goPrev = () => {
      if (swiper !== null) {
        goPrevHandler();
        (swiper as any).slidePrev();
      }
    };

    const swiperParams = {
      slidesPerView: cardsInARow,
      spaceBetween: spaceBetween,
      shouldSwiperUpdate: true,
      onSwiper: updateSwiper,
      slidesPerGroup,
      breakpoints: breakpoints,
    };

    return (
      <div>
        <EntrySection>{entrySection}</EntrySection>
        <StyledCarousel $spaceBetween={spaceBetween}>
          <StyledSwiper>
            {/* @ts-expect-error TS(2745): This JSX tag's 'children' prop expects type 'React... Remove this comment to see the full error message */}
            <Swiper {...swiperParams}>{children}</Swiper>
          </StyledSwiper>
          <Controls>
            <Conditional if={!(swiper as any)?.isBeginning}>
              <div
                className="prev-slide"
                role="button"
                tabIndex={0}
                onClick={goPrev}
              >
                {ChevronLeftCircle}
              </div>
            </Conditional>
            <Conditional if={!(swiper as any)?.isEnd}>
              <div
                className="next-slide"
                role="button"
                tabIndex={0}
                onClick={goNext}
              >
                {ChevronLeftCircle}
              </div>
            </Conditional>
          </Controls>
        </StyledCarousel>
      </div>
    );
  }

  return (
    <>
      <Conditional if={entrySection}>
        <EntrySection>{entrySection}</EntrySection>
      </Conditional>
      {/* @ts-expect-error TS(2745): This JSX tag's 'children' prop expects type 'React... Remove this comment to see the full error message */}
      <OverflowScroll minWidthChild={mobileMinWidth} marginBottom={0}>
        {children}
      </OverflowScroll>
    </>
  );
};
export default Carousel;
